import React, { useState, useEffect, useCallback } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import algoliasearch from 'algoliasearch/lite';
import { Link } from 'gatsby';
import { throttle, debounce } from 'throttle-debounce';

let client;
let index;

const Search = () => {
  const [query, setQuery] = useState('');
  const [hits, setHits] = useState([]);
  const [nbHits, setNbHits] = useState(0);

  useEffect(() => {
    client = algoliasearch(
      process.env.GATSBY_ALGOLIASEARCH_APPLICATION_ID,
      process.env.GATSBY_ALGOLIASEARCH_SEARCH_ONLY_API_KEY,
    );
    index = client.initIndex(process.env.GATSBY_ALGOLIASEARCH_INDEX_NAME);
  }, []);

  const searchThrottled = useCallback(
    throttle(500, (q) => search(q)),
    [],
  );
  const searchDebounced = useCallback(
    debounce(500, (q) => search(q)),
    [],
  );

  const search = async (q) => {
    if (q !== '') {
      const result = await index.search(q);
      setHits(result.hits);
      setNbHits(result.nbHits);
    } else {
      setHits([]);
      setNbHits(0);
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
    if (query.length < 6) {
      searchThrottled(query);
    } else {
      searchDebounced(query);
    }
  };

  return (
    <>
      <Form>
        <FormControl
          type="text"
          placeholder="Zoeken"
          className="my-2"
          value={query}
          onChange={handleChange}
        />
      </Form>
      {nbHits === 1 && <p>{nbHits} zoekresultaat</p>}
      {nbHits > 1 && <p>{nbHits} zoekresultaten</p>}
      <ul>
        {hits.length > 0 &&
          hits.map((hit, index) => {
            return (
              <li key={index}>
                <Link to={`/producten/${hit.fields.slug['en-US']}`}>
                  {hit.fields.name['en-US']}
                </Link>
              </li>
            );
          })}
      </ul>
      {hits.length === 0 && <p>Geen zoekresultaten...</p>}
    </>
  );
};

export default Search;
